<template>
  <div class="star_page">
      <img src="@/assets/images/star/loading.gif" alt="">
  </div>
</template>

<script>

export default {
  name: 'Star',
  components: {
  }
}
</script>
<style scoped lang='scss'>
.star_page{
  text-align: center;
  img{
    width: 1788px;
    margin: 60px auto;
    display: block;
  }
}
</style>
